import Footer from "./footer/footer";
import Navbar from "./navbar/navbar";
import Service1 from "./service1/service1";
import Service2 from "./service2/service2";
import Service3 from "./service3/service3";



const Service = ()=>{
    return(
        <>
        <Navbar/>
        <Service1/>
        <Service2/>
        <Service3/>
        <Footer/>
        
        </>
    )
}
export default Service;