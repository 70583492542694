import Contactus from "./contactus/contactus";
import Footer from "./footer/footer";
import Navbar from "./navbar/navbar";


const Contact =()=>{
    return(
        <>
        <Navbar/>
        <Contactus/>
        <Footer/>
        </>
    )
}
export default Contact;